

.sub-item:hover {
  font-weight: bold;
  background-color:#fafafa
}

/* Animación para el popup */
.popup-container {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .popup-container.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .menu-items-hover:hover{
    background-color: #fafafa
  }
